<template>
  <div class="content-container">
    <div v-if="extraGamesData" class="header">
      <p class="questions-count"><span style="font-weight: 500">{{ extraGamesData.totalItems }}</span>{{ $t('CLASSES_HELPDESK.TOTAL_EXTRA_GAMES' ) }}</p>
    </div>
    <CustomTable :options="linkedExtraGamesOptions"
                 :pagination-data="extraGamesData"
                 :table-data="extraGamesData.items"
                 :less-padding="true"
                 :search-function="manageSearch"
                 :hide-search="true"

                 table-class="schools-table linked-classes"
                 class="classes-table">
      <template #id="props">
        <div>{{ props.rowData.id }}</div>
      </template>
      <template #title="props">
        <div>{{ props.rowData.name }}</div>
      </template>
      <template #count="props">
        <div>{{ props.rowData.timesPlayed }}</div>
      </template>
      <template #time="props">
        <div>{{ props.rowData.timeNeeded }}</div>
      </template>
      <template #bits="props">
        <div>{{ props.rowData.bitsEarned }}</div>
      </template>
      <template #actions="props">
        <div class="action-button-group">
          <ActionButton
            :text="$t('CLASSES_HELPDESK.MODIFY')"
            :icon="require('../../../assets/icons/icn_edit.svg')"
            custom-class="button-blue"
            class="action"
            @click="() => onEditButtonPressed(props)"
          />
        </div>
      </template>
    </CustomTable>

  </div>
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import ActionButton from '@/components/table/ActionButton'
import CustomTable from '@/components/table/CustomTable'
import { getTableOptions, LINKED_EXTRA_GAMES } from '@/utils/helpers/customTable/CustomTableHelper'
import { GET_EXTRA_GAMES_INSIGHT } from '@/store/modules/helpdesk/actions'

export default {
  name: 'InsightExtraGames',
  components: {
    CustomTable,
    ActionButton,
  },
  props: {
    onEditButtonPressed: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const store = useStore()
    const route = useRoute()
    const classId = route.params.classId
    const extraGamesData = ref({})

    function manageSearch(payload) {
      store.dispatch(GET_EXTRA_GAMES_INSIGHT, { classId, payload }).then(response => {
        extraGamesData.value = response.data
      })
    }

    return {
      manageSearch,
      extraGamesData,
      linkedExtraGamesOptions: getTableOptions(LINKED_EXTRA_GAMES),
    }
  },
  template: 'InsightExtraGames'
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

h1 {
  margin: rem(30) 0 rem(20) 0;
}

.cell-custom-table {
  padding: 0 0 0 0;
}

.questions-count{
  margin: auto 0 auto auto;
  color: gray;
  text-align: right;
}

.header{
  display: flex;
}

.status{
  font-weight: 800;
}

.published{
  color: rgb(81,84,104)
}

.concept{
 color: rgb(123,150,179)
}
</style>
